<template>
  <base-card :lessPadding="true">
    <div class="chosen-bookings">
      <h2 class="choose-bookings-header">Velg bane og tidspunkt:</h2>
      <multiselect :isBookingPage="true" />
      <hr class="seperator" />
      <h5 class="chosen-bookings-header">Valgte bookinger:</h5>
      <div v-if="isBookings">
        <table align="center" class="table">
          <thead>
            <th>Dato</th>
            <th>Tid</th>
            <th>Område</th>
          </thead>
          <tbody>
            <tr v-for="b in chosenBookings" :key="b">
              <td>{{ b.date }}</td>
              <td>{{ b.time }}</td>
              <td id="field-col">{{ b.field }}</td>
              <button
                class="remove-booking-button-temp"
                @click="removeBooking(b)"
              >
                <handle-x theme="filled" size="24" fill="#B40000" />
              </button>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-success" @click="toCheckout()">Gå videre</button>
      </div>
      <div v-else><p>Du har ikke valgt noen bookinger enda.</p></div>
    </div>
  </base-card>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { HandleX } from "@icon-park/vue-next";
import BaseCard from "@/components/common/BaseCard.vue";
import Multiselect from "@/components/common/Multiselect.vue";

export default defineComponent({
  name: "ChosenBookings",
  components: { HandleX, BaseCard, Multiselect },
  setup() {
    const store = useStore();
    const router = useRouter();

    const trigger = ref(""); //made only to fetch chosenBookings onMounted
    const isBookings = ref(true);

    const chosenBookings = computed(() => {
      trigger.value;
      // sorterer bookingene så de havner kronologisk etter dato, så tidspunkt, så område
      const bookings = store.getters.getChosenBookings;
      const sortedBookings = bookings.sort((obj1: any, obj2: any) => {
        // lager dato-objekt av datoene
        const dateSplitObj1 = obj1.date.split(":");
        const dayObj1 = dateSplitObj1[0];
        const monthObj1 = dateSplitObj1[1] - 1;
        const yearObj1 = dateSplitObj1[2];
        const dateObj1 = new Date(yearObj1, monthObj1, dayObj1);
        const dateSplitObj2 = obj2.date.split(":");
        const dayObj2 = dateSplitObj2[0];
        const monthObj2 = dateSplitObj2[1] - 1;
        const yearObj2 = dateSplitObj2[2];
        const dateObj2 = new Date(yearObj2, monthObj2, dayObj2);

        // sorterer på dato
        if (dateObj1 > dateObj2) {
          return 1;
        } else if (dateObj1 < dateObj2) {
          return -1;
        }

        // sorterer på tid
        if (obj1.time > obj2.time) {
          return 1;
        } else if (obj1.time < obj2.time) {
          return -1;
        }

        // sorterer på område
        if (obj1.field > obj2.field) {
          return 1;
        } else if (obj1.field < obj2.field) {
          return -1;
        }
      });
      return sortedBookings;
    });

    watch([chosenBookings.value, trigger], () => {
      if (chosenBookings.value.length >= 1) {
        isBookings.value = true;
      } else {
        isBookings.value = false;
      }
    });

    const removeBooking = (booking: Record<string, unknown>) => {
      store.commit({
        type: "removeChosenBooking",
        pickedBooking: booking,
      });
    };

    const toCheckout = async () => {
      router.push({ path: "/fhbooking/booking/checkout" });
    };

    onMounted(() => {
      trigger.value = "f";
    });

    return {
      chosenBookings,
      isBookings,
      removeBooking,
      toCheckout,
    };
  },
});
</script>
<style scoped>
.choose-bookings-header {
  margin-bottom: 3%;
}
.seperator {
  margin-top: 8%;
  margin-bottom: 8%;
}
.chosen-bookings {
  text-align: center;
}
.remove-booking-button-temp {
  background-color: rgba(255, 0, 0, 0);
  border: none;
}
.btn-success {
  margin-top: 2rem;
}
#field-col {
  min-width: 70px;
}
</style>
