
import { defineComponent } from "vue";
import { Back } from "@icon-park/vue-next";
import { useRouter } from "vue-router";
import PickDate from "../../components/common/PickDate.vue";
import SeeBookings from "../../components/common/SeeBookings.vue";
import ChooseBookings from "../../components/booking/ChooseBookings.vue";

export default defineComponent({
  name: "Booking",
  components: { PickDate, SeeBookings, ChooseBookings, Back },
  setup() {
    const pickDateTitle = "Velg ønsket dag:";
    const seeBookingsTitle = "Se ledige tidspunkter:";
    const dateLimit = new Date();
    const router = useRouter();

    const toFrontpage = () => {
      router.push({ path: "/fhbooking" });
    };
    return { pickDateTitle, seeBookingsTitle, dateLimit, toFrontpage };
  },
});
