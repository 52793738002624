
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { HandleX } from "@icon-park/vue-next";
import BaseCard from "@/components/common/BaseCard.vue";
import Multiselect from "@/components/common/Multiselect.vue";

export default defineComponent({
  name: "ChosenBookings",
  components: { HandleX, BaseCard, Multiselect },
  setup() {
    const store = useStore();
    const router = useRouter();

    const trigger = ref(""); //made only to fetch chosenBookings onMounted
    const isBookings = ref(true);

    const chosenBookings = computed(() => {
      trigger.value;
      // sorterer bookingene så de havner kronologisk etter dato, så tidspunkt, så område
      const bookings = store.getters.getChosenBookings;
      const sortedBookings = bookings.sort((obj1: any, obj2: any) => {
        // lager dato-objekt av datoene
        const dateSplitObj1 = obj1.date.split(":");
        const dayObj1 = dateSplitObj1[0];
        const monthObj1 = dateSplitObj1[1] - 1;
        const yearObj1 = dateSplitObj1[2];
        const dateObj1 = new Date(yearObj1, monthObj1, dayObj1);
        const dateSplitObj2 = obj2.date.split(":");
        const dayObj2 = dateSplitObj2[0];
        const monthObj2 = dateSplitObj2[1] - 1;
        const yearObj2 = dateSplitObj2[2];
        const dateObj2 = new Date(yearObj2, monthObj2, dayObj2);

        // sorterer på dato
        if (dateObj1 > dateObj2) {
          return 1;
        } else if (dateObj1 < dateObj2) {
          return -1;
        }

        // sorterer på tid
        if (obj1.time > obj2.time) {
          return 1;
        } else if (obj1.time < obj2.time) {
          return -1;
        }

        // sorterer på område
        if (obj1.field > obj2.field) {
          return 1;
        } else if (obj1.field < obj2.field) {
          return -1;
        }
      });
      return sortedBookings;
    });

    watch([chosenBookings.value, trigger], () => {
      if (chosenBookings.value.length >= 1) {
        isBookings.value = true;
      } else {
        isBookings.value = false;
      }
    });

    const removeBooking = (booking: Record<string, unknown>) => {
      store.commit({
        type: "removeChosenBooking",
        pickedBooking: booking,
      });
    };

    const toCheckout = async () => {
      router.push({ path: "/fhbooking/booking/checkout" });
    };

    onMounted(() => {
      trigger.value = "f";
    });

    return {
      chosenBookings,
      isBookings,
      removeBooking,
      toCheckout,
    };
  },
});
