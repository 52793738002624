<template>
  <h1 class="page-title">Finstadbru Hundesport Booking</h1>
  <div class="back-button">
    <button class="btn" @click="toFrontpage()">
      <back theme="filled" size="24" fill="#2c3e50" />
      <strong class="button-text">Tilbake</strong>
    </button>
  </div>
  <div class="wrapper">
    <div class="one">
      <pick-date :title="pickDateTitle" :limit="dateLimit" />
    </div>
    <div class="two"><choose-bookings /></div>
    <div class="three">
      <see-bookings :title="seeBookingsTitle" :isBookingPage="true" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Back } from "@icon-park/vue-next";
import { useRouter } from "vue-router";
import PickDate from "../../components/common/PickDate.vue";
import SeeBookings from "../../components/common/SeeBookings.vue";
import ChooseBookings from "../../components/booking/ChooseBookings.vue";

export default defineComponent({
  name: "Booking",
  components: { PickDate, SeeBookings, ChooseBookings, Back },
  setup() {
    const pickDateTitle = "Velg ønsket dag:";
    const seeBookingsTitle = "Se ledige tidspunkter:";
    const dateLimit = new Date();
    const router = useRouter();

    const toFrontpage = () => {
      router.push({ path: "/fhbooking" });
    };
    return { pickDateTitle, seeBookingsTitle, dateLimit, toFrontpage };
  },
});
</script>
<style scoped>
.back-button {
  display: inline-block;
  margin: 0 3% 3% 3%;
  text-align: left;
}
.button-text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5%;
}
@media screen and (min-width: 992px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
  .one {
    grid-column: 1;
    grid-row: 1;
  }
  .two {
    margin-top: 10%;
    grid-column: 1;
    grid-row: 2;
  }
  .three {
    grid-column: 2;
    grid-row: 1 / 4;
  }
}
</style>
